import React from "react";
import { useState, useEffect, useContext } from "react";
import { Menu, Dropdown, Avatar, Switch } from "antd";
import { connect } from 'react-redux'
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  WalletOutlined,
  UserOutlined
} from '@ant-design/icons';
import { onSwitchTheme,onHeaderNavColorChange } from 'redux/actions/Theme';
import Icon from 'components/util-components/Icon';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { signOut } from 'redux/actions/Auth';
import { GlobalContext } from "providers/globalProvider";
import { useThemeSwitcher } from "react-css-theme-switcher";


const menuItem = [
  {
    title: "Edit Profile",
    icon: EditOutlined,
    path: `${APP_PREFIX_PATH}/profile`
  },
  {
    title: "Billing",
    icon: WalletOutlined,
    path: `${APP_PREFIX_PATH}/billing`
  },
  //   {
  // 	title: "Account Setting",
  // 	icon: SettingOutlined,
  // 	path: "/"
  //   },
  //   {
  // 	title: "Billing",
  // 	icon: ShopOutlined ,
  // 	path: `${APP_PREFIX_PATH}/billing`
  // },
  {
    title: "Help Center",
    icon: QuestionCircleOutlined,
    path: "${APP_PREFIX_PATH}/contact"
  }
]

export const NavProfile = ({ signOut }) => {
  const { switcher, themes, currentTheme } = useThemeSwitcher();
  const [theme, setTheme] = useState(currentTheme);


  const [userData, setUserData] = useState(null);

  const { globalData } = useContext(GlobalContext);

  useEffect(() => {
    if (globalData.user) {
      setUserData(globalData.user)
    }
  }, [globalData.user])


  const profileImg = userData?.photoURL ? userData?.photoURL : "/img/avatars/user.png"
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <a href={`${APP_PREFIX_PATH}/profile`}><Avatar size={45} src={userData?.photoURL} /></a>
          <div className="pl-3">
            <h4 className="mb-0">{userData?.displayName}</h4>
            <span className="text-muted">Premium member</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem.length + 1}>

            <Icon className="mr-3" type={SettingOutlined} />
            <span className="font-weight-normal">Dark Mode</span>
            <Switch className="float-right" onChange={(e) => {
              onHeaderNavColorChange('')
              const changedTheme = e ? 'dark' : 'light'
              onSwitchTheme(changedTheme)
              switcher({ theme: themes[changedTheme] });
              localStorage.getItem('THEME_CONFIG') && localStorage.setItem('THEME_CONFIG', JSON.stringify({ ...JSON.parse(localStorage.getItem('THEME_CONFIG')), currentTheme: changedTheme }))
              //reload page
              //window.location.reload();
            }} style={{ marginTop: 5 }} defaultChecked={theme === 'dark'} />


          </Menu.Item>

          <Menu.Item key={menuItem.length + 2} onClick={e => signOut()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default connect(null, { signOut })(NavProfile)
