import {
  AppstoreOutlined,
  HddOutlined,
  CloudServerOutlined,
  ApiOutlined,
  ClockCircleOutlined,
  RobotFilled,
  ToolOutlined,
  ShopOutlined,
  SplitCellsOutlined,
  CreditCardTwoTone,
  CodeOutlined,
  RocketOutlined,
  ReadOutlined,
  BarChartOutlined,
  FolderViewOutlined,
  SafetyCertificateOutlined,
  ClusterOutlined,
  ApartmentOutlined,
  DesktopOutlined,
  HourglassOutlined,
  BoxPlotOutlined,
  ProfileOutlined,
  FieldTimeOutlined,
  AppstoreAddOutlined,
  TableOutlined,
  ShopTwoTone,
  ShoppingTwoTone,
  CloudTwoTone,
  GlobalOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig';





//Servers Selection Menu
const serversSelectionMenu = [{
  // key: 'serversSelection',
  // title: 'Server',
  // breadcrumb: false,
  // submenu: [
  //   {
      key: 'servers',
      path: `${APP_PREFIX_PATH}/servers/overview`,
      title: `Servers`,
      icon: CloudTwoTone,
      breadcrumb: false,
      submenu: []
  //   }
  // ]
}];

// Development Menu
const developementMenu = [{
  key: 'development',
  path: `${APP_PREFIX_PATH}/development`,
  title: 'Development',
  icon: CodeOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'applications',
      path: `${APP_PREFIX_PATH}/applications`,
      title: 'Applications',
      icon: AppstoreOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'application-code',
          path: `${APP_PREFIX_PATH}/applications/code`,
          title: 'Code',
          icon: CodeOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'application-deployment',
          path: `${APP_PREFIX_PATH}/applications/deployment`,
          title: 'Deployment',
          icon: RocketOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'application-marketplace',
          path: `${APP_PREFIX_PATH}/applications/marketplace`,
          title: 'Marketplace',
          icon: ShopOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'application-documentation',
          path: `${APP_PREFIX_PATH}/applications/documentation`,
          title: 'Documentation',
          icon: ReadOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'application-usage',
          path: `${APP_PREFIX_PATH}/applications/usages`,
          title: 'Usage',
          icon: BarChartOutlined,
          breadcrumb: false,
          submenu: []
        }
      ]
    },
    {
      key: 'storage',
      path: `${APP_PREFIX_PATH}/storage`,
      title: 'Storage',
      icon: CloudServerOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'storage-explorer',
          path: `${APP_PREFIX_PATH}/storage/explorer`,
          title: 'Explorer',
          icon: FolderViewOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'storage-security',
          path: `${APP_PREFIX_PATH}/storage/security`,
          title: 'Security',
          icon: SafetyCertificateOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'storage-documentation',
          path: `${APP_PREFIX_PATH}/storage/documentation`,
          title: 'Documentation',
          icon: ReadOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'storage-usage',
          path: `${APP_PREFIX_PATH}/storage/usage`,
          title: 'Usage',
          icon: BarChartOutlined,
          breadcrumb: false,
          submenu: []
        }
      ]
    },
    {
      key: 'databases',
      path: `${APP_PREFIX_PATH}/databases`,
      title: 'Databases',
      icon: HddOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'database-explorer',
          path: `${APP_PREFIX_PATH}/database/explorer`,
          title: 'Explorer',
          icon: ClusterOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'database-security',
          path: `${APP_PREFIX_PATH}/database/security`,
          title: 'Security',
          icon: SafetyCertificateOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'database-documentation',
          path: `${APP_PREFIX_PATH}/database/documentation`,
          title: 'Documentation',
          icon: ReadOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'database-usage',
          path: `${APP_PREFIX_PATH}/database/usage`,
          title: 'Usage',
          icon: BarChartOutlined,
          breadcrumb: false,
          submenu: []
        }
      ]
    },
    {
      key: 'apis',
      path: `${APP_PREFIX_PATH}/apis`,
      title: "API's",
      icon: ApiOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'api-code',
          path: `${APP_PREFIX_PATH}/api/code`,
          title: 'Code',
          icon: CodeOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'api-deployment',
          path: `${APP_PREFIX_PATH}/api/deployment`,
          title: 'Deployment',
          icon: RocketOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'api-explorer',
          path: `${APP_PREFIX_PATH}/api/explorer`,
          title: 'API Explorer',
          icon: ApartmentOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'api-marketplace',
          path: `${APP_PREFIX_PATH}/api/marketplace`,
          title: 'Marketplace',
          icon: ShopOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'api-documentation',
          path: `${APP_PREFIX_PATH}/api/documentation`,
          title: 'Documentation',
          icon: ReadOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'api-usage',
          path: `${APP_PREFIX_PATH}/api/usages`,
          title: 'Usage',
          icon: BarChartOutlined,
          breadcrumb: false,
          submenu: []
        }
      ]
    },
    {
      key: 'crons',
      path: `${APP_PREFIX_PATH}/crons`,
      title: 'Cron Jobs',
      icon: FieldTimeOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'cron-code',
          path: `${APP_PREFIX_PATH}/cron/code`,
          title: 'Code',
          icon: CodeOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'cron-scheduler',
          path: `${APP_PREFIX_PATH}/cron/scheduler`,
          title: 'Scheduler',
          icon: HourglassOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'cron-documentation',
          path: `${APP_PREFIX_PATH}/cron/documentation`,
          title: 'Documentation',
          icon: ReadOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'cron-usage',
          path: `${APP_PREFIX_PATH}/cron/usage`,
          title: 'Usage',
          icon: BarChartOutlined,
          breadcrumb: false,
          submenu: []
        }
      ]
    }
  ]
}];

// Analytics Menu
const analyticsMenu = [{
  key: 'analytics',
  path: `${APP_PREFIX_PATH}/analytics`,
  title: 'Analytics',
  icon: BarChartOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'monitor',
      path: `${APP_PREFIX_PATH}/monitor`,
      title: 'Monitor',
      icon: DesktopOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'monitor-apps',
          path: `${APP_PREFIX_PATH}/monitor/apps`,
          title: 'Apps Health',
          icon: AppstoreOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'monitor-apis',
          path: `${APP_PREFIX_PATH}/monitor/apis`,
          title: "API's Health",
          icon: ApiOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'monitor-crons',
          path: `${APP_PREFIX_PATH}/monitor/crons`,
          title: 'Crons Health',
          icon: ClockCircleOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'monitor-documentation',
          path: `${APP_PREFIX_PATH}/monitor/documentation`,
          title: 'Documentation',
          icon: ReadOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'monitor-usage',
          path: `${APP_PREFIX_PATH}/monitor/usages`,
          title: 'Usage',
          icon: BarChartOutlined,
          breadcrumb: false,
          submenu: []
        }
      ]
    },
    {
      key: 'loggers',
      path: `${APP_PREFIX_PATH}/loggers`,
      title: 'Loggers',
      icon: AppstoreOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: 'loggers-overview',
          path: `${APP_PREFIX_PATH}/loggers/overview`,
          title: 'Overview',
          icon: BoxPlotOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'loggers-logs',
          path: `${APP_PREFIX_PATH}/loggers/logs`,
          title: "Logs",
          icon: ProfileOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'loggers-documentation',
          path: `${APP_PREFIX_PATH}/loggers/documentation`,
          title: 'Documentation',
          icon: ReadOutlined,
          breadcrumb: false,
          submenu: []
        },
        {
          key: 'loggers-usage',
          path: `${APP_PREFIX_PATH}/loggers/usages`,
          title: 'Usage',
          icon: BarChartOutlined,
          breadcrumb: false,
          submenu: []
        }
      ]
    }
  ]
}];

const servicesMenu = [{
  key: 'services',
  path: `${APP_PREFIX_PATH}/services`,
  title: 'Services',
  icon: ApartmentOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'services-tools',
      path: `${APP_PREFIX_PATH}/services/tools`,
      title: 'Tools',
      icon: ToolOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'services-gateways',
      path: `${APP_PREFIX_PATH}/services/gateways`,
      title: 'Gateways',
      icon: SplitCellsOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}];

const marketplaceMenu = [{
  // key: 'marketplace',
  // path: `${APP_PREFIX_PATH}/marketplace`,
  // title: 'Marketplace',
  // breadcrumb: false,
  // submenu: [
  //   {
      key: 'marketplace-store',
      path: `${APP_PREFIX_PATH}/marketplace/overview`,
      title: 'MarketPlace',
      icon: ShopTwoTone,
      breadcrumb: false,
      submenu: []
  //   }
  // ]
}];

// Global Menu
const globalMenu = [{
  key: 'global',
  title: 'Global',
  icon: GlobalOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'marketplace-store',
      path: `${APP_PREFIX_PATH}/marketplace/overview`,
      title: 'MarketPlace',
      icon: ShopTwoTone,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'global-billing',
      path: `${APP_PREFIX_PATH}/billing`,
      title: 'Billing',
      icon: CreditCardTwoTone,
      breadcrumb: false,
      submenu: []
    }
  ]
}];




const products = [{
  key: 'products',
  path: `${APP_PREFIX_PATH}/products`,
  title: 'Products',
  breadcrumb: false,
  submenu: [
    {
      key: 'projectbase-3d',
      path: `${APP_PREFIX_PATH}/3d`,
      title: `3D Base`,
      icon: TableOutlined,
      breadcrumb: false,
      submenu: []
    },
    ...developementMenu,
    ...analyticsMenu,
    ...servicesMenu,
    ...marketplaceMenu
  ]
}];

const SiaAI = [{
  key: 'sia',
  path: `${APP_PREFIX_PATH}/sia`,
  title: 'SIA AI ✨',
  breadcrumb: false,
  icon: RobotFilled,
  submenu: []
}];


const services = [{
  key: 'services',
  path: `${APP_PREFIX_PATH}/services`,
  title: 'Services',
  breadcrumb: false,
  submenu: [
    {
      key: 'webapps',
      path: `${APP_PREFIX_PATH}/webapps`,
      title: 'Web Apps',
      icon: GlobalOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'APIs',
      path: `${APP_PREFIX_PATH}/services/apis`,
      title: 'APIs',
      icon: ApiOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'Databases',
      path: `${APP_PREFIX_PATH}/services/databases`,
      title: 'Databases',
      icon: HddOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'Storage',
      path: `${APP_PREFIX_PATH}/services/storage`,
      title: 'Storage',
      icon: CloudServerOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'Crons',
      path: `${APP_PREFIX_PATH}/services/crons`,
      title: 'Crons',
      icon: ClockCircleOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}];





var navigationConfig = []


navigationConfig = [
  // ...SiaAI,
  // ...serversSelectionMenu,
  // ...marketplaceMenu,
  ...services,
  ...globalMenu,
  // ...products,

  // ...developementMenu,
  // ...analyticsMenu,
  // ...servicesMenu,
  // ...marketplaceMenu
]


export default navigationConfig;


