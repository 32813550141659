import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { Layout, Tag, Select, Switch, Popover, Badge, message, notification } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, SearchOutlined, ExclamationCircleFilled, CheckCircleFilled, LoadingOutlined } from '@ant-design/icons';
import Logo from './Logo';
import NavNotification from './NavNotification';
import NavProfile from './NavProfile';
import NavLanguage from './NavLanguage';
import NavPanel from './NavPanel';
import NavSearch from './NavSearch';
import SearchInput from './NavSearch/SearchInput.js'
import { toggleCollapsedNav, onMobileNavToggle } from 'redux/actions/Theme';
import { NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import utils from 'utils';
import { db, auth } from 'auth/FirebaseAuth';
import { useHistory, useParams } from 'react-router-dom';
import { Detector } from "react-detect-offline";
import { GlobalContext } from "providers/globalProvider";
const { Option } = Select;


const { Header } = Layout;

const onlineStatus = (
  <>
    <h4><span style={{ marginRight: '5px' }}><CheckCircleFilled style={{ color: '#87d068' }} /></span>Internet Status</h4>
    <p>You Internet Connection is Stable.</p>
  </>
);

const offlineStatus = (
  <>
    <h4><span style={{ marginRight: '5px' }}><ExclamationCircleFilled style={{ color: '#f50' }} /></span>Internet Status</h4>
    <p>Unstable Internet Connection Detected</p>
  </>
);


export const HeaderNav = (props) => {
  const { navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile, currentTheme, direction } = props;
  const [searchActive, setSearchActive] = useState(false)
  const { globalData, serversData, currentServer, setCurrentServer } = useContext(GlobalContext);


  const onSearchClose = () => {
    setSearchActive(false)
  }
  const accounts = []

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed)
    } else {
      onMobileNavToggle(!mobileNav)
    }
  }

  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff')
    }
    return utils.getColorContrast(headerNavColor)
  }
  const navMode = mode()
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px'
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`
    } else {
      return `${SIDE_NAV_WIDTH}px`
    }
  }

  useEffect(() => {

    if (!isMobile) {
      onSearchClose()
    }


  }, [])


  const { label, value, closable, onClose } = props;


  let navigate = useHistory();
  console.log("Servers Data in Header Nav");

  return (
    <Header className={`app-header ${navMode}`} style={{ backgroundColor: headerNavColor }}>
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={navMode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {
                isNavTop && !isMobile ?
                  null
                  :
                  <li className="ant-menu-item ant-menu-item-only-child" onClick={() => { onToggle() }}>
                    {navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon" /> : <MenuFoldOutlined className="nav-icon" />}
                  </li>
              }


            </ul>
          </div>
          {/* <div className="nav mt-3" style={{ width: '100%'}}>
            {demoToggle == false ? 
              <div style={{width: '100%', textAlign: 'center', marginBottom: '10px'}}>
              <Tag color="yellow">Demo</Tag>
            </div>
            :
            <></>  
          }
          </div> */}
          <div className="nav-center">
            {/* <Select
              showSearch
              style={{ width: '90%' }}
              dropdownMatchSelectWidth={false}
              placeholder="Select Server &nbsp;&nbsp;&nbsp;&nbsp;"
              optionFilterProp="children"
              defaultValue={null}
              notFoundContent="No Servers Found"
              onChange={(value) => {
                console.log(value)
                const server = serversData.find((server) => server.name == value)
                setCurrentServer(server)
                localStorage.setItem('currentServer', server.name)
              }}
              // onFocus={onFocus}
              // onBlur={onBlur}
              // onSearch={onSearch}
              filterOption={(input, option) =>{
                return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
            >
              {serversData.map((server) => {
                return <Option value={server.name}>
                  <strong>{server.name}</strong>
                  <br />
                  <small>{server.ip}</small>
                  </Option>
              })}
            </Select> */}

          </div>
          <div className="nav-right">


            <Detector //onChange={(online)=>{if(online){ message.success({content:'You are back online ! 😃',key: 'internetpopup'}) }else{ message.error({content:'You are offline now! 🥺',key: 'internetpopup'}) } }}
              render={({ online }) => {
                if (!online) {
                  notification.warning({
                    message: 'Internet Status',
                    description: <>
                      <p>Unstable Internet Connection Detected <br /> <LoadingOutlined spin /> Retrying to connect...</p>
                    </>,
                    placement: 'bottomRight',
                    duration: 0,
                    key: 'internetpopup',
                    icon: <ExclamationCircleFilled style={{ color: '#f50' }} />,
                  });
                } else {
                  notification.destroy('internetpopup')
                }
                return (
                  <>
                    <Popover placement="bottom" content={online ? onlineStatus : offlineStatus}>
                      <Badge status="processing" color={online ? '#87d068' : '#f50'} />
                    </Popover>
                  </>
                )
              }}
            />

            {/* <Popover placement="bottom" content={helpContent}>
                <Switch
                  className="mt-4"
                  style={{ minWidth: 'fit-content' }}
                  checkedChildren='Live On'
                  unCheckedChildren='Live Off'
                  defaultChecked={demoToggle}
                  onChange={changeDemoToggle}
                />
              </Popover> */}

            <NavNotification />
            {/* <NavLanguage /> */}
            {/* <NavPanel direction={direction} /> */}
            <NavProfile />
          </div>
          {/* <NavSearch active={searchActive} close={onSearchClose} /> */}
        </div>
      </div>
    </Header>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction } = theme;
  return { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction }
};

export default connect(mapStateToProps, { toggleCollapsedNav, onMobileNavToggle })(HeaderNav);