import { auth, googleAuthProvider, githubAuthProvider } from 'auth/FirebaseAuth';
import { signInWithEmailAndPassword, signOut, signInWithPopup, createUserWithEmailAndPassword } from 'firebase/auth';

const FirebaseService = {}

FirebaseService.signInEmailRequest = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (err) {
    return err;
  }
}

FirebaseService.signOutRequest = async () => {
  try {
    await signOut(auth);
    return 'User signed out';
  } catch (err) {
    return err;
  }
}

FirebaseService.signInGoogleRequest = async () => {
  try {
    const userCredential = await signInWithPopup(auth, googleAuthProvider);
    return userCredential.user;
  } catch (err) {
    return err;
  }
}

FirebaseService.signInGithubRequest = async () => {
  try {
    const userCredential = await signInWithPopup(auth, githubAuthProvider);
    return userCredential.user;
  } catch (err) {
    return err;
  }
}

FirebaseService.signUpEmailRequest = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (err) {
    return err;
  }
}
export default FirebaseService