const FirebaseConfig = 
// {
//   apiKey: "AIzaSyDXuw7vet6ghlD0_FjsyifoGu-1OJCER4Y",
//   authDomain: "hasbase-com.firebaseapp.com",
//   projectId: "hasbase-com",
//   storageBucket: "hasbase-com.appspot.com",
//   messagingSenderId: "808174257056",
//   appId: "1:808174257056:web:239b302d979a31b1de9ac5",
//   measurementId: "G-4EQ870YNFT"
// };
{
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

export default FirebaseConfig

