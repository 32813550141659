import React, { lazy, Suspense, useEffect, useState, useContext } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { Spin, notification,message } from 'antd';
import Icon from '@ant-design/icons';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { db, auth, analytics, messaging } from '../../auth/FirebaseAuth';
import { logEvent } from 'firebase/analytics';
import { getToken, onMessage } from "firebase/messaging";
import { doc, setDoc } from "firebase/firestore";
import { GlobalContext } from "../../providers/globalProvider";

export const AppViews = () => {
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
  const history = useHistory();
  const [userData, setUserData] = useState(null);
  const { setGlobalData } = useContext(GlobalContext);

  //getting current user uid
  useEffect(() => {

    auth.onAuthStateChanged(async function (user) {
      if (user) {

        try{
          // check if local storage has cli data
          const cli = localStorage.getItem('cli');
          if(cli){
            var redirectURL = cli;
            localStorage.removeItem('cli');

            // get user ID token
            const idTokenResult = await user.getIdTokenResult();
            const token = idTokenResult.token;

            // redirect to redirectURL
            window.location.href = redirectURL+'?token='+token;
          }

        }catch(err){
          console.log(err);
        }

        setUserData(user);

        logEvent(analytics,'User_logged_in_hasbase', {
          content_type: 'json',
          content_id: new Date(),
          items: user
        });


        if(messaging != null){
        getToken(messaging,{ vapidKey: 'BO2J9u27Qaf93Ic5H9DMaezXLWykNDkzSkQ9Sl2FtMG80Mbi_KlBkUkhwxz-VezLPeMxoJbFSHoMCPLfruTTLj4' }).then(async (currentToken) => {
          if (currentToken) {
            console.log(currentToken);
            await setDoc(doc(db, 'users', user.uid, 'settings', 'fcm'), {
              web: currentToken
            }, { merge: true });

          } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          // ...
        });

        onMessage(messaging,(payload) => {
          console.log('Message received. ', payload);
          notification.open({
            message: payload.notification.title,
            description: payload.notification.body,
            onClick: () => {
              console.log('Notification Clicked!');
              history.replace('/app/notifications');
            },
          });
          // ...
        })
      }

      // Check if user has a phone number
      if (user.phoneNumber == null) {
        console.log(user);
        console.log('user has no phone number');
        message.error({ content: 'Please verify your phone number', key: 'verifyphone', duration: 2 });
        history.replace('/app/verify');
      }

      // accessToken
      const idTokenResult = await user.getIdTokenResult();
      setGlobalData({ user: { ...user, token: idTokenResult.token } });

      }
      else {
        console.log('no user');
        localStorage.clear();
        window.location.href = '/';
      }
    });



  }, [])


  return (
    <>
      {!userData ?
        <Spin />
        :
        userData.phoneNumber == null ?
          <Suspense fallback={<Loading cover="content" />}>
            <Switch>
              <Route path={`${APP_PREFIX_PATH}/verify`} component={lazy(() => import(`./verify`))} />
              <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/verify`} />
            </Switch>
          </Suspense>
          :
        <Suspense fallback={<Loading cover="content" />}>
          <Switch>
            <Route path={`${APP_PREFIX_PATH}/projects`} component={lazy(() => import(`./projects`))} />
            <Route path={`${APP_PREFIX_PATH}/databases`} component={lazy(() => import(`./databases`))} />
            <Route path={`${APP_PREFIX_PATH}/storage`} component={lazy(() => import(`./storage`))} />
            <Route path={`${APP_PREFIX_PATH}/applications`} component={lazy(() => import(`./applications`))} />
            <Route path={`${APP_PREFIX_PATH}/profile`} component={lazy(() => import(`./profile`))} />
            <Route path={`${APP_PREFIX_PATH}/billing`} component={lazy(() => import(`./billing`))} />
            <Route path={`${APP_PREFIX_PATH}/notifications`} component={lazy(() => import(`./notifications`))} />
            <Route path={`${APP_PREFIX_PATH}/servers`} component={lazy(() => import(`./servers`))} />
            <Route path={`${APP_PREFIX_PATH}/3d`} component={lazy(() => import(`./3d`))} />
            <Route path={`${APP_PREFIX_PATH}/verify`} component={lazy(() => import(`./verify`))} />
            <Route path={`${APP_PREFIX_PATH}/marketplace`} component={lazy(() => import(`./marketplace`))} />
            <Route path={`${APP_PREFIX_PATH}/apps`} component={lazy(() => import(`./apps`))} />
            <Route path={`${APP_PREFIX_PATH}/sia`} component={lazy(() => import(`./sia`))} />

            <Route path={`${APP_PREFIX_PATH}/webapps`} component={lazy(() => import(`./webapps`))} />
            <Route path={`${APP_PREFIX_PATH}/webapp/:id`} component={lazy(() => import(`./webapps/details`))} />

            <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/webapps`} />
          </Switch>
        </Suspense>
      }
    </>

  )


}

export default React.memo(AppViews);