import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'


export const APP_NAME = 'Hasbase';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

export var THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'dark',
	direction: DIR_LTR
};

// THEME_CONFIG = JSON.parse(localStorage.getItem('THEME_CONFIG')) || THEME_CONFIG;
if(JSON.parse(localStorage.getItem('THEME_CONFIG'))) {
	THEME_CONFIG = JSON.parse(localStorage.getItem('THEME_CONFIG'));
}else{
	THEME_CONFIG = THEME_CONFIG;
	localStorage.setItem('THEME_CONFIG', JSON.stringify(THEME_CONFIG));
}