import React, { useState, useEffect } from 'react';
import { Menu, Dropdown, Badge, Avatar, List, Button, Tooltip } from 'antd';
import {
  MailOutlined,
  BellOutlined,
  WarningOutlined,
  CheckCircleOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  InfoCircleFilled
} from '@ant-design/icons';
// import notificationData from "assets/data/notification.data.json";
import Flex from 'components/shared-components/Flex';
import { db } from '../../auth/FirebaseAuth';
import { doc, collection, query, orderBy, limit, onSnapshot, getDocs, updateDoc } from "firebase/firestore";


const getIcon = icon => {
  switch (icon) {
    case 'mail':
      return <MailOutlined />;
    case 'alert':
      return <WarningOutlined />;
    case 'check':
      return <CheckCircleOutlined />
    default:
      return <MailOutlined />;
  }
}

// const goToLinkedPage = (link) => {
//   if (link != "") {
//     window.location.assign(link);
//   }
// }

const getNotificationBody = list => {
  return (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={item => (
        <List.Item className="list-clickable">
          <Flex alignItems="center">
            <div className="pr-3">
              {item.type == "success" ?
                <CheckCircleFilled style={{ fontSize: '18px', color: '#04d182' }} />
                :
                item.type == "failure" ?
                  <CloseCircleFilled style={{ fontSize: '18px', color: '#ff6b72' }} />
                  :
                  <InfoCircleFilled style={{ fontSize: '18px', color: '#fadb14' }} />
              }
            </div>
            <div className="mr-3">
              <span className="text-dark">{item.heading} </span>
            </div>
            <small className="ml-5">{item.time}</small>
          </Flex>
        </List.Item>
      )}
    />
  )
}

export const NavNotification = () => {

  const [visible, setVisible] = useState(false);
  const [notifications, setNotiifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  var notificationsArr = [];

  useEffect(() => {

    onSnapshot(doc(db, 'users', localStorage.getItem('auth_token')), async (snapshot) => {
      if (snapshot.exists()) {
        var data = snapshot.data();
        setUnreadCount(data.unreadCount)
    
        if (data.unreadCount == 0) {
          setNotiifications([]);
        }
        else {
          var readLimit;
          if (data.unreadCount > 10) {
            readLimit = 10;
          }
          else {
            readLimit = data.unreadCount
          }
    
          const q = query(
            collection(db, 'users', localStorage.getItem('auth_token'), 'notifications'),
            orderBy('time', 'desc'),
            limit(readLimit)
          );
    
          const querySnapshots = await getDocs(q);
    
          if (!querySnapshots.empty) {
            notificationsArr = [];
            setNotiifications([]);
            querySnapshots.docs.forEach((doc) => {
              var nData = doc.data();
    
              var t1 = new Date();
              var t2 = new Date(nData.time.toDate());
              var today = t1.getDate();
              var day = t2.getDate();
    
              var t = "";
    
              if ((today - day) != 0) {
                t = day + " " + (t2.toLocaleString('default', { month: 'long' }));
              }
              else {
                t = t2.getHours() + ":" + t2.getMinutes();
              }
    
              var link = "";
              if ("link" in nData) {
                link = nData.link
              }
    
              var heading = nData.heading.split(" ").splice(0, 8).join(" ");
    
              var obj = {
                heading: heading,
                time: t,
                type: nData.type,
                link: link
              }
    
              notificationsArr.push(obj);
    
            })
          }
          else {
            notificationsArr = [];
          }
    
          setNotiifications(notificationsArr)
        }
      }
      else {
        setUnreadCount(0)
      }
    })
  }, [])


  const handleVisibleChange = (flag) => {
    setVisible(flag);
  }


  const resetUnreadCount = async () => {
    await updateDoc(doc(db, 'users', localStorage.getItem('auth_token')), {
      unreadCount: 0
    });

    setUnreadCount(0);
  }

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        <Tooltip
          title="Click to mark all the notification as red"
        >
          <Button onClick={resetUnreadCount} className="text-primary" type="link" size="small">Mark as read</Button>
        </Tooltip>
      </div>
      {
        notifications.length > 0 ?
          <div className="nav-notification-body">
            {getNotificationBody(notifications)}
          </div>
          :
          <div className="empty-notification">
            <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="empty" />
            <p className="mt-3">No new notification!</p>
          </div>
      }
      <div className="nav-notification-footer">
        <a className="d-block" href="/app/notifications">View all</a>
      </div>
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={['click']}
    >
      <Menu mode="horizontal">
        <Menu.Item>
          <Badge count={unreadCount}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  )
}


export default NavNotification;
