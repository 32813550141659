import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, GithubAuthProvider, PhoneAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';
import { getMessaging, isSupported } from 'firebase/messaging';
import { getDatabase } from 'firebase/database';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import firebaseConfig from 'configs/FirebaseConfig';

const app = initializeApp(firebaseConfig);

// Firebase utils
const rdb = getDatabase(app);
const db = getFirestore(app);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const currentUser = auth.currentUser;
const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();
const twitterAuthProvider = new TwitterAuthProvider();
const githubAuthProvider = new GithubAuthProvider();
const phoneAuthProvider = new PhoneAuthProvider();
const messaging = isSupported() ? getMessaging(app) : null;
const appCheck = initializeAppCheck(app, {
	provider: new ReCaptchaV3Provider('6LfvjcwkAAAAAOT2EXBxEWb_gbVx27TFezyW2y8d'),
	// Optional argument. If true, the SDK automatically refreshes App Check
	// tokens as needed.
	isTokenAutoRefreshEnabled: true,
});

auth.useDeviceLanguage();

export {
    app,
    rdb,
    db,
    auth,
    currentUser,
    analytics,
    googleAuthProvider,
    facebookAuthProvider,
    twitterAuthProvider,
    githubAuthProvider,
    phoneAuthProvider,
    messaging,
    storage,
};
